/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Muli:400,600,700');

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
    font-family: Muli;
}

h1, h2 {
    font-weight: 600;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
}

.btn {
    margin-bottom: 10px;
}

.btn-primary {
    margin-right: 10px;
}

/*
.btn {
    padding: 10px 10px;
    border: 0 none;
    letter-spacing: 1px;
    font-family: Muli;
    font-weight:600;
    // width:100px;
}

.btn-primary {
    background: #59A049;
    color: #ffffff;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
    background: #81bf73;
}

.btn-primary:active, .btn-primary.active {
    background: #59A049;
    box-shadow: none;
}
*/

.modal-backdrop {
    display: none;
}

.modal {
    background: rgba(0,0,0,0.5);
    white-space: pre-line;
}

.col-sm-9 {
    padding-top: 20px;
    padding-left: 0px;
}

div.mat-form-field-flex div.mat-form-field-infix {
    display: flex;
    align-items: center;
}

.mat-form-field-infix svg {
    margin-bottom: 10px;
    border-radius: 10px;
}

.mat-form-field-infix svg:hover {
    background-color: #E8E8E8;
}

mat-datepicker-content table {
    border: none;
}

mat-form-field:hover div.mat-form-field-infix {
    background-color: #FBFBFB;
    border-radius: 10px;
}

div.mat-form-field-infix {
    transition: background-color .5s ease;
}

.btn-success {
    background-color: #D6EBC3;
    border: none;
    color: black;
}

.btn-danger {
    background-color: #FF6C6C;
    border: none;
    color: black;
}